import Head from "next/head";
import React from "react";

export default function MetaTagSSR(props) {
  const d = props?.metaData;

  // Check if the schema is already present in the script array
  const hasSchema = d?.script?.some((script) => script?.type === "application/ld+json");

  //console.log(props,"propspropsprops")
  return (
    <Head key={d?.id}>
      {/* Title and Meta Tags */}
      <title>{d?.title}</title>
      <meta charSet="utf-8" />
      <meta property="title" content={d?.title} />
      <meta property="keyword" content={d?.keyword} />
      <meta name="description" content={d?.description} />
      <link rel="canonical" href={d?.canonical} />
      <meta property="og:title" content={d?.title} />
      <meta property="og:description" content={d?.description} />
      <meta property="og:url" content={d?.canonical} />
      <meta property="og:image" content={d?.og_image} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={d?.title} />
      <meta name="twitter:description" content={d?.description} />
      <meta name="twitter:image" content={d?.twitter_image} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content={d?.robots_follow} />
      {/* <link rel="icon" href={d?.favicon_icon} /> */}
      <meta httpEquiv="content-language" content="en" />

      {/* Dynamically Render Scripts */}
      {d?.script?.map((script) => {
        if (script?.type === "application/ld+json") {
          return (
            <script
              key={script?.id}
              type={script?.type}
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(script?.content),
              }}
            />
          );
        }
        return (
          <script
            key={script?.id}
            type={script?.type}
            name={script?.name}
            src={script?.url}
            async
          />
        );
      })}

      {/* Static JSON-LD for the Website (only render if schema is not already included in d?.script) */}
      {!hasSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Your Website",
              "url": d?.canonical,
            }),
          }}
        />
      )}
    </Head>
  );
}
